.dialog {
  .titleContainer {
    display: flex;
    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .subTitle {
      font-size: 14px;
      color: rgb(133, 133, 133);
    }

    .buttonDiv {
      display: flex;
      gap: 8px;
      margin-left: auto;
      height: 80%;
    }
  }

  .content {
    display: flex;
    height: 80vh;
    .chartDiv {
      width: 80%;
    }
    .optionsDiv {
      padding: 8px;
      .add {
        button {
          text-transform: none;
          padding-left: 0;
          margin-top: 4px;
        }
      }
    }
    .iconButton {
      svg {
        font-size: 18px;
      }
    }
  }
}
