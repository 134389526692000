.drawer {
  .paper {
    margin-top: 50px;
    z-index: 1;
    width: 400px;
    border-right: 1px solid #ebebeb;
  }
  .noTopMargin {
    margin-top: 0px;
  }

  .parent {
    padding: 12px;
    .top {
      display: flex;
      position: sticky;
      top: 0;
      align-items: center;
      margin-bottom: 16px;
      .text {
        font-size: 12px;
        font-weight: 700;
      }
      .iconButton {
        margin-left: auto;
        padding: 0px;
        svg {
          font-size: 18px;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .title {
        display: flex;
        align-items: center;
        .text {
          font-size: 12px;
          font-weight: 600;
        }
      }
      .querySelect {
        display: flex;
        align-items: center;
        .text {
          font-size: 12px;
          font-weight: 600;
        }
        .autocomplete {
          width: 80%;
          margin-left: auto;
        }
      }
    }
  }
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    display: flex;
    align-items: center;
    .text {
      font-size: 12px;
      font-weight: 600;
    }
    .colContainer {
      margin-top: 8px;
      padding: 12px;
      border: 1px solid #ebebeb;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      width: 93%;
      .colDetail {
        align-items: flex-start;
        display: flex;
        gap: 8px;
        flex-direction: column;
        .content {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .colLabel{
        width: 100%;
        text-transform: none;
        font-size: 12px;
        font-weight: 600;
        justify-content: flex-start;
        color: #000;
        border-radius: 4px;
        padding: 2px 8px;
      }
      .colBtn {
        width: 100%;
        text-transform: none;
        font-size: 12px;
        font-weight: 600;
        justify-content: flex-start;
        color: #000;
        border-radius: 4px;
        padding: 2px 8px;
        svg {
          font-size: 14px;
        }
        &.hidden {
          color: #888;
        }
        span {
          margin-left: auto;
        }
        &:hover {
          background-color: #f3f3f3;
          .iconBtn {
            svg {
              display: block;
            }
          }
        }
        .iconBtn {
          padding: 0px 4px;
          width: 100%;
          justify-content: flex-end;
          background-color: transparent;
          svg {
            font-size: 14px;
            display: none;
          }
        }
      }
    }
  }
  .chart {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .text {
      font-size: 12px;
      font-weight: 600;
    }
    .chartType {
      display: flex;
      gap: 24px;
      padding: 4px;
      .icon {
        border-radius: 4px;
        padding: 0px;
        border: 1px solid #ebebeb;
        &:hover {
          border: 1px solid #4b0082;
        }
      }
    }
    .accordionAxis {
      font-size: 12px;
      font-weight: 600;
      box-shadow: none;
      border: 1px solid #ebebeb;
      .summary {
        svg {
          font-size: 18px;
          color: #000;
        }
      }
      .removeButton {
        padding: 0px;
        &:hover {
          svg {
            fill: #000;
          }
        }
        svg {
          font-size: 14px;
          fill: #bcbcbc;
        }
      }
      .addButton {
        text-transform: none;
        font-size: 12px;
        color: #000;
        font-weight: 600;
        margin-left: 16px;
        padding: 4px;
        &:hover {
          background: transparent;
          svg {
            fill: #000;
          }
        }
        svg {
          fill: #bcbcbc;
          font-size: 18px;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .axis {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 8px 4px;
        }
        .option {
          display: flex;
          align-items: center;
          .autocomplete {
            width: 80%;
            margin-left: auto;
          }
        }
      }
    }
  }
}
