.workspace {
  .container {
    .gridContainer {
      background-color: inherit;
      width: calc(100% - 32px);
      .newTile {
        border: 1px solid var(--stroke-color, #eff0f6);
        border-radius: 8px;
        background-color: #fff;
        padding: 12px;
        &:hover {
          .topBar {
            .right {
              .iconButtonSmall {
                display: flex;
              }
            }
          }
        }
        .topBar {
          display: flex;
          align-items: center;
          .title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
          }
          .right {
            display: flex;
            gap: 8px;
            margin-left: auto;
            .iconButtonSmall {
              display: none;
              padding: 0px;
              svg {
                font-size: 14px;
              }
            }
          }
        }
        .tile {
          margin-top: 6px;
          .value {
            font-size: 24px;
            font-weight: 700;
          }
        }
        .table {
          padding: 12px 0px;
        }
      }
    }
  }
}
