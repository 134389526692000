.root {
  margin: 8px 0px 8px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.customLegend {
  display: flex;
  max-height: 20vh;
  overflow: auto;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  .legendRow {
    display: flex;
    font-size: 0.6vw;
    align-items: center;
    gap: 6px;
    .fill {
      border-radius: 3px;
      width: 14px;
      height: 10px;
    }
  }
}

// .legendWrapper{
//   position: static !important;
// }