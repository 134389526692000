.root {
    margin-top: 64px;
    margin-bottom: 64px;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  
    .header {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      width: 600px;
  
      button {
        text-transform: none;
        margin-left: auto;
        background-color: #4b0082;
        font-weight: 800;
      }
    }
  
    .wrapper {
      width: 600px;
      border: 1px solid #e5e5e5;
      border-width: 1px 0px 1px 0px;
      .tile {
        display: flex;
        flex-direction: row;
        padding: 12px 0px 12px 0px;
        font-size: 14px;
        gap: 16px;
        align-items: center;
        .connectionName {
          font-weight: 400;
        }
        button {
          text-transform: none;
          margin-left: auto;
        }
  
        .iconGroup {
          margin-left: auto;
          button {
            svg {
                font-size: 18px;
            }
          }
        }
      }
    }
  }
  
  .dialog {
    .paper {
      min-width: 500px;
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
    }
  
    .title {
      font-weight: 600;
      font-size: 14px;
    }
  
    .subTitle {
      font-size: 14px;
      color: rgb(133, 133, 133);
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      .dbTile {
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        font-size: 14px;
        text-transform: none;
        color: black;
      }
  
      .disabledTile {
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        font-size: 14px;
        text-transform: none;
        background-color: rgba(247, 247, 248, 1);
        color: grey;
      }
    }
  }
  
  .dialogDetail {
    .paper {
      min-width: 700px;
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
    }
  
    .title {
      font-weight: 600;
      font-size: 14px;
    }
  
    .subTitle {
      font-size: 14px;
      color: rgb(133, 133, 133);
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      .block {
        display: flex;
        flex-direction: column;
        gap: 6px;
        flex: 1;
  
        .label {
          font-size: 14px;
          font-weight: 600;
        }
      }
  
      .adjacent {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
  
      .ip {
        font-size: 13px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        padding: 8px;
        background-color: rgba(247, 247, 248, 1);
      }
  
      .footer {
        margin-left: auto;
  
        .button {
          text-transform: none;
          margin: 4px;
          background: #4b0082;
          font-weight: 800;
        }
      }
    }
  }
  
  .tableDialog {
    .paper {
      min-width: 500px;
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
    }
  
    .title {
      font-weight: 600;
      font-size: 14px;
    }
  
    .subTitle {
      font-size: 14px;
      color: rgb(133, 133, 133);
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      .tile {
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        background-color: rgba(247, 247, 248, 1);
        font-size: 14px;
        text-transform: none;
        color: black;
  
        .label {
          padding: 0px 12px 0px 12px;
        }
      }
    }
  
    .footer {
      margin-left: auto;
  
      .button {
        text-transform: none;
        margin-right: 20px;
        margin-bottom: 12px;
        background: #4b0082;
        font-weight: 800;
      }
    }
  }
  
  .metadataDialog {
    .drawer {
      .listItemActive {
        background-color: #f6f2f9;
        border-radius: 6px;
        width: 95%;
        margin: 4px;
      }
      .listItem {
        border-radius: 6px;
        &:hover {
          background-color: #f6f2f9;
        }
        width: 95%;
        margin: 4px;
      }
    }
  
    .paper {
      min-width: 1000px;
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
    }
  
    .title {
      font-weight: 600;
      font-size: 14px;
      margin-left: 18%;
    }
  
    .subTitle {
      font-size: 14px;
      color: rgb(133, 133, 133);
      margin-left: 18%;
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-left: 18%;
  
      .divider {
        margin-top: 4px;
        font-weight: 600;
        font-size: 14px;
        .button {
          text-transform: none;
          border-radius: 4px;
          color: #000;
          font-weight: 600;
          background: transparent;
          font-family: inherit;
          &:hover {
            background: #faf8ff;
          }
        }
      }
  
      .block {
        display: flex;
        flex-direction: column;
        gap: 6px;
        flex: 1;
  
        .label {
          font-size: 14px;
          font-weight: 600;
        }
      }
  
      .tile {
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        background-color: rgba(247, 247, 248, 1);
        font-size: 14px;
        text-transform: none;
        color: black;
  
        .label {
          padding: 0px 12px 0px 12px;
        }
      }
    }
  
    .footer {
      margin-left: auto;
  
      .button {
        text-transform: none;
        margin-right: 20px;
        margin-bottom: 12px;
        background: #4b0082;
        font-weight: 800;
      }
    }
  }
  
  .popover {
    .options {
      padding: 0px 4px 0px 4px;
  
      button {
        text-transform: none;
      }
    }
  }
  
  @keyframes fade-in {
    0% { opacity: 0; height: 0; }
    100% { opacity: 1; height: 21px; }
  }
  