.brandDrawer {
  .paper {
    margin-top: 50px;
    margin-left: 40px;
    border-right: 1px solid #ebebeb;
    width: 180px;
  }
  .content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .text {
        font-size: 12px;
        font-weight: 700;
      }
      .iconButton {
        margin-left: auto;
        padding: 0px;
        svg {
          font-size: 18px;
        }
      }
    }
    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .text {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
