.root {
  // border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 12px;
}

.linearPrimary {
  background-color: #faf8ff !important;
}
.linearBarPrimary {
  background-color: #4b0082 !important;
}

.customToolbar {
  display: flex;
  gap: 8px;
  padding: 8px 12px 8px 4px;
  margin-left: auto;
  .textButton {
    text-transform: none;
    color: #4b0082;
    border-radius: 8px;
    border: 1px solid rgba(224, 224, 224, 1);
    background-color: #faf8ff;
    svg {
      font-size: 14px;
    }
    &:hover {
      background-color: #faf8ff;
      box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
    }
  }
  .embed {
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    border: 1px solid rgba(224, 224, 224, 1);
    text-transform: none;
    &:hover {
      background-color: #f6f6f6;
      box-shadow: none;
    }
  }
  .smallTextButton {
    text-transform: none;
    color: #4b0082;
    border-radius: 8px;
    font-size: 8px;
    border: 1px solid rgba(224, 224, 224, 1);
    background-color: #faf8ff;
    svg {
      font-size: 10px;
    }
    &:hover {
      background-color: #faf8ff;
      box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
    }
  }
}

.addDashboardPopover {
  .paper {
    min-width: 10vw;
    margin-top: 8px;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    button {
      text-transform: none;
      color: #4b0082;
      &:hover {
        background: #faf8ff;
      }
    }
  }
}
