.addDrawer {
  .paper {
    margin-top: 50px;
    margin-left: 40px;
    border-right: 1px solid #ebebeb;
    width: 180px;
  }

  .parent {
    padding: 12px;
    .top {
      display: flex;
      align-items: center;
      .text {
        font-size: 12px;
        font-weight: 700;
      }
      .iconButton {
        margin-left: auto;
        padding: 0px;
        svg {
          font-size: 18px;
        }
      }
    }
    .tiles {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      font-size: 10px;
      font-weight: 500;
      gap: 8px;
      .horizontal {
        display: flex;
        flex-direction: row;
        gap: 8px;
        .tile {
          padding: 4px;
          .icon {
            border-radius: 12px;
            background-color: #f9f9f9;
            border: 0.5px solid #f9f9f9;
            &:hover {
                border-color: #ebebeb;
            }
            img {
              border-radius: 12px;
              background-color: #f9f9f9;
            }
          }
          .title {
            text-align: center;
          }
        }
      }
    }
  }
}
