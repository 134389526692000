.root {
  min-height: 100vh;
  // margin-left: 24px;
  width: 100vw;
  // background: #faf8ff;
  .iconButton {
    svg {
      font-size: 20px;
      // color: #4b0082;
    }
  }
  .embedContainer {
    display: flex;
    gap: 12px;
    .save {
      color: #fff;
      text-transform: none;
      margin-top: 24px;
      font-size: 14px;
      background-color: #4b0082;
      // border: 1px solid #4b0082;
    }
    .disabled {
      opacity: 0.2;
    }
  }
  .topbar {
    .toolbar {
      padding: 6px 8px;
      min-height: 36px;
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      .customers {
        margin-left: 20px;
        display: flex;
        gap: 12px;
        align-items: center;
        .title {
          font-weight: 600;
        }
      }
      .homeButton {
        padding: 0px;
      }
      .title {
        color: #000;
        font-weight: 600;
        font-size: 14px;
        margin-left: 32px;
      }
      .right {
        margin-right: 16px;
        margin-left: auto;
        display: flex;
        gap: 12px;
        button {
          padding: 0px;
          text-transform: none;
          font-size: 12px;
          font-weight: 600;
        }
        .preview {
          color: #4b0082;
          text-transform: none;
          font-size: 12px;
          border: 1px solid #4b0082;
          &:hover {
            background-color: transparent;
          }
        }
        .save {
          color: #fff;
          font-size: 12px;
          background-color: #4b0082;
          border: 1px solid #4b0082;
          padding: 4px;
        }
      }
    }
  }
}

.miniDrawer {
  .paper {
    margin-top: 50px;
    border-right: 1px solid #ebebeb;
  }
  .content {
    display: flex;
    flex-direction: column;
    .iconButton {
      margin: 4px;
      padding: 4px;
      border-radius: 8px;
      &:hover {
        background-color: #ebebeb;
      }
      &.active {
        background-color: #ebebeb;
      }
      svg {
        font-size: 22px;
      }
    }
  }
}

.chatDialog {
  .paper {
    // min-width: 80vw;
    // height: 85vh;
    border-radius: 8px;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
  .paperNonEmbed {
    min-width: 80vw;
    height: 85vh;
    border-radius: 8px;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
  .toolbar {
    display: flex;
    .activeConnection {
      border-radius: 8px;
      color: #fff;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      font-weight: 600;
      border-radius: 8px;
      background: #270044;
      font-size: 14px;
      gap: 12px;
      &:hover {
        border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
      }
    }
  }
}

.historyDrawer {
  box-sizing: border-box;
  padding: 5px;
  transition: width 0.2s;
  .paper {
    background-color: #fbfbfb;
    z-index: 50;
    border-right: 1px solid #ebebeb;
    width: 12rem;
  }
  .pane {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    .button {
      padding: 0px 20%;
      margin: auto;
      border-radius: 8px;
      text-transform: none;
      background-color: #fff;
      z-index: 100;
      color: black;
      font-weight: 600;
      border: 2px solid #8B5AAE;
      box-shadow: none;
      cursor: pointer;
      &:hover {
        background-color: #faf8ff;
      }
      // svg{
      //   color: #4b0082;
      // }
    }
  }
  .hist{
    display: flex;
    flex-direction: column;
    overflow: auto;
    .text {
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    .linearPrimary {
      background-color: #faf8ff !important;
    }
    .linearBarPrimary {
      background-color: #4b0082 !important;
    }

    .tile {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      margin: 2px 0px;
      display: flex;
      padding: 8px;
      align-items: center;
      font-size: 14px;
      border: 1px solid #E9E9EA;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
      .title {
        flex: 1;
        font-weight: 600;
        align-self: flex-start;
      }
      .date{
        color: #5C5C5C;
        font-size: 12px;
        margin-top: 5px;
        align-self: flex-end;
      }
      .icon {
        visibility: hidden;
        svg {
          font-size: 18px;
        }
      }
      &:hover {
        .icon {
          visibility: visible;
        }
      }
    }
  }
}
