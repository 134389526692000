.tile {
  border-radius: 8px;
  border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
  background: var(--white, #fff);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .key {
    color: #4F4F4F;
  }
  .value {
    color: #000;
    font-weight: 700;
  }
}
