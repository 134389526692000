.canvaDrawer {
  margin-left: 74px;
  box-sizing: border-box;
  padding: 5px;
  transition: width 0.2s;
  .paper {
    background-color: #FBFBFB;
    z-index: 50;
    margin-left: 74px;
    border-right: 1px solid #ebebeb;
    width: 300px;
  }
  .content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .text {
        font-size: 12px;
        font-weight: 700;
      }
      .iconButton {
        margin-left: auto;
        padding: 0px;
        svg {
          font-size: 18px;
        }
      }
    }
    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .text {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .pane {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px dotted #ebebeb;
    .button {
      padding: 0px 15%;
      margin: 12px;
      border-radius: 8px;
      text-transform: none;
      background-color: #fff;
      z-index: 100;
      color: black;
      font-weight: 600;
      border: 2px solid #8B5AAE;
      box-shadow: none;
      cursor: pointer;
      &:hover {
        background-color: #faf8ff;
      }
      svg{
        color: #4b0082;
      }
    }
    .closeDrawer {
      margin: 12px;
      border-radius: 8px;
      text-transform: none;
      background-color: #fff;
      z-index: 100;
      color: #4b0082;
      cursor: pointer;
      &:hover {
        background-color: #faf8ff;
      }
    }
  }
  .hist{
    display: flex;
    flex-direction: column;
    overflow: auto;
    .text {
      font-size: 12px;
      font-weight: 600;
    }
    .linearPrimary {
      background-color: #faf8ff !important;
    }
    .linearBarPrimary {
      background-color: #4b0082 !important;
    }

    .tile {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      margin: 2px 0px;
      display: flex;
      padding: 8px;
      align-items: center;
      font-size: 14px;
      border: 1px solid #E9E9EA;
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
      .title {
        flex: 1;
        font-weight: 600;
        align-self: flex-start;
      }
      .date{
        color: #5C5C5C;
        font-size: 12px;
        margin-top: 5px;
        align-self: flex-end;
      }
      .icon {
        visibility: hidden;
        svg {
          font-size: 18px;
        }
      }
      &:hover {
        .icon {
          visibility: visible;
        }
      }
    }
  }
}