.root {
  .toolbar {
    background-color: #1e0034;
    // min-height: 24px;
    .activeConnection {
      border-radius: 8px;
      padding: 4px 16px;
      display: flex;
      align-items: center;
      margin-left: 68px;
      font-weight: 600;
      border-radius: 8px;
      background: #270044;
      font-size: 14px;
      gap: 12px;
      &:hover {
        border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
      }
    }
    .tab {
      margin-left: 68px;
      div {
        gap: 16px;
        display: flex;
        align-items: center;
        a {
          border-radius: 8px;
          font-size: 14px;
          padding: 8px 16px;
          color: #fff;
          text-transform: none;
          text-decoration: none;
          font-weight: 800;
          &:hover,
          &.active {
            background-color: rgba(255,255,255,.12);
          }
        }
      }
    }
    .profile {
      margin-left: auto;
      display: flex;
      align-items: center;
      .info {
        flex-direction: column;
        .name {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
        .email {
          color: #b5b5b5;
          font-size: 12px;
          letter-spacing: 0.05px;
          font-weight: 600;
        }
      }
    }
  }
  .drawer {
    .drawerPaper {
      border-right: 0px !important;
      z-index: 100;
      background-color: #1e0034;
      margin-top: 50px;
    }
    .drawerHeader {
      color: #fff;
      font-size: 32px;
      font-weight: 800;
      margin-right: auto;
      margin-left: 50px;
    }
    .item {
      span {
        font-size: 14px;
        font-weight: 600;
      }
      display: flex;
      text-decoration: none;
      width: 90%;
      align-items: center;
      color: #bab1c1;
      margin: 12px 0px 12px 12px;
      border-radius: 8px;
      height: 2.4rem;
      svg {
        color: #cccccc;
        font-size: 22px;
      }
      &:hover {
        background: var(--hover-bg-fill, rgba(75, 0, 130, 0.2));
        color: #fff;
        svg {
          color: #fff;
        }
      }
    }
    .active {
      border-left: 2px solid #fff;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background: rgba(75, 0, 130, 0.6);
      color: #fff;
      svg {
        color: #fff;
      }
    }
    .link {
      text-decoration: none;
      align-items: center;
      display: flex;
      color: #bab1c1;
      width: 100%;
      &:hover {
        color: #fff;
      }
    }
    .activeLink {
      color: #fff;
    }
  }
}

.history {
  height: 24vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.history::-webkit-scrollbar {
  display: none;
}

.dashboardCollapse {
  height: 18vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.dashboardCollapse::-webkit-scrollbar {
  display: none;
}