.root {
  margin-top: 64px;
  margin-left: 240px;
  padding: 64px 128px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .panelTitle {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    .right {
      margin-left: auto;
      display: flex;
      gap: 8px;
      .createButton {
        text-transform: none;
        padding: 0px 8px;
        background-color: #4b0082;
        font-weight: 600;
      }
    }
  }
  .table {
    border-top: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .headerTile {
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 64px;
      font-size: 12px;
      color: #808080;
      font-weight: 600;
      .title {
        flex: 1;
      }
      .bogus {
        visibility: hidden;
      }
    }
    .tile {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 128px;
      font-size: 14px;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
      .title {
        flex: 1;
        font-weight: 600;
      }
      .right {
        .icon {
          visibility: hidden;
          svg {
            font-size: 18px;
          }
        }
        .iconDisabled {
          visibility: hidden;
          svg {
            font-size: 18px;
          }
        }
      }
      &:hover {
        .right .icon {
          visibility: visible;
        }
      }
    }
  }
}
