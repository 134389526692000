.dialog {
  .paper {
    min-width: 500px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .footer {
    margin-left: auto;

    .button {
      text-transform: none;
      margin-right: 20px;
      margin-bottom: 12px;
      background: #4b0082;
      font-weight: 800;
    }
  }
}
