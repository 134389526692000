.root {
  display: flex;
  flex-direction: column;
  height: inherit;
  .top {
    max-height: calc(90vh - 64px);
    overflow: auto;
    flex: 1;

    .content {
      .innerDiv {
        padding: 24px;
        margin: 24px;
        display: flex;
        gap: 24px;
        .logoTile {
          margin-top: 10px;
        }
        border-radius: 8px;
        border: 1px solid rgba(75, 0, 130, 0.1);
        background: #fff;
        .welcome {
          display: flex;
          flex-direction: column;
          gap: 12px;
          font-size: 14px;
          padding-right: 12px;
          .sampleQuestions {
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 8px;
            .preDefined {
              padding: 4px 6px;
              cursor: pointer;
              border-radius: 6px;
              // border: 1px solid var(--primary-dark);
              background: #efe9ff;
              &:hover {
                box-shadow: 0px 0px 4px 0px var(--primary-dark);
              }

              .textq {
                font-size: 14px;
                font-family: inherit;
                font-weight: 500;
                text-transform: none;
                color: #000;
                padding: 0px;
                &:hover {
                  background: inherit;
                }
              }
            }
          }
          .explore {
            text-transform: none;
            background: #4b0082;
            font-weight: 600;
            color: #fff;
          }
        }
        .explanation {
          display: flex;
          label {
            margin-left: 0px;
            span {
              font-weight: 600;
            }
          }
        }
        .markdown {
          display: block;
          font-size: 14px;
          padding-right: 12px;

          pre {
            font-size: 14px;
            code {
              font-weight: 500;
            }
          }

          code {
            font-weight: 600;
          }
        }
        .sqlBar {
          display: flex;
          padding-right: 12px;
          label {
            margin-left: 0px;
            span {
              font-weight: 600;
            }
          }
        }
        .nudge {
          font-size: 14px;
          margin-top: 0px;
          display: flex;
          align-items: center;
          gap: 4px;
          a {
            text-decoration: none;
            color: #4b0082;
            transition: 0.4s ease-in;
            &:hover {
              box-shadow: 0 1px 0 #4b0082;
            }
          }
          background-color: #faf8ff;
          padding: 4px;
          border-radius: 8px;
          svg {
            color: #4b0082;
          }
        }
        .graph {
          display: flex;
          flex-direction: column;
          border: 1px solid rgba(224, 224, 224, 1);
          border-radius: 12px;
          min-height: 40vh;
          padding: 0px 12px 0px 4px;
          .container {
            min-height: 35vh;
          }
        }
        .tile {
          display: flex;
          flex-direction: column;
          border: 1px solid rgba(224, 224, 224, 1);
          border-radius: 12px;
          padding: 0px 12px 12px 12px;
        }
        .textButton {
          text-transform: none;
          color: #4b0082;
          border-radius: 8px;
          border: 1px solid rgba(224, 224, 224, 1);
          background-color: #faf8ff;
          svg {
            font-size: 14px;
          }
          &:hover {
            box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
          }
        }
        .embed {
          background-color: #fff;
          border-radius: 8px;
          color: #000;
          border: 1px solid rgba(224, 224, 224, 1);
          text-transform: none;
          &:hover {
            background-color: #f6f6f6;
            box-shadow: none;
          }
        }
        .iconButton {
          svg {
            font-size: 18px;
          }
        }
        .loading {
          width: fit-content;
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 14px;
          padding: 0px 4px;
          background-color: #faf8ff;
          border: 1px solid #ebebeb;
          border-radius: 4px;
        }
        .suggestions {
          overflow-x: auto;
          display: flex;
          flex-direction: row;
          gap: 8px;
          .block {
            display: flex;
            border: 1px solid #ebebeb;
            background-color: #fff;
            cursor: pointer;
            &:hover {
              border: 1px solid #4b0082;
              box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
            }
            align-items: center;
            border-radius: 8px;
            padding: 8px;
            gap: 8px;
            flex: 1;
            .title {
              font-size: 14px;
              font-weight: 400;
              width: max-content;
            }
            svg {
              font-size: 10px;
              color: #4b0082;
            }
          }
        }
        .suggestions::-webkit-scrollbar {
          display: none;
        }
        .clarifyingQuestions {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          .clarify {
            .question {
              font-weight: 600;
            }
            .choices {
              margin-top: 4px;
              display: flex;
              flex-direction: row;
              gap: 8px;
              .choice {
                border-radius: 4px;
                padding: 8px;
                font-weight: 400;
                border: 1px solid #ebebeb;
                background-color: #fff;
                cursor: pointer;
                &:hover {
                  border: 1px solid var(--primary-dark);
                  box-shadow: 0px 1px 4px 0px var(--primary-dark);
                }
              }
              .selected {
                border: 1px solid var(--primary-dark);
                box-shadow: 0px 1px 4px 0px var(--primary-dark);
              }
            }
          }
          .proceed {
            display: flex;
            gap: 16px;
            .choiceSubmit {
              margin-left: auto;
              text-transform: none;
              background: #4b0082;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
  .iconButton {
    svg {
      font-size: 20px;
      color: var(--primary-dark);
    }
  }
}

.graphPopper {
  .paper {
    margin-top: 8px;
    overflow: hidden;
    padding: 4px;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
  }
  .iconButton {
    svg {
      font-size: 14px;
      color: #4b0082;
    }
  }
  .footerNudge {
    margin-top: 4px;
    display: flex;
    gap: 4px;
    font-size: 14px;
  }
}

.feedbackPopper {
  .paper {
    margin-top: 8px;
    padding: 8px;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
    min-width: 500px;
  }
  .footer {
    display: flex;
    button {
      margin-left: auto;
      text-transform: none;
      background: #4b0082;
      font-weight: 800;
    }
  }
}

.addDashboardPopover {
  .paper {
    min-width: 10vw;
    margin-top: 8px;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    button {
      text-transform: none;
      color: #4b0082;
      &:hover {
        background: #faf8ff;
      }
    }
  }
}

.editChartDialog {
  .titleContainer {
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .right {
      margin-left: auto;
      display: flex;
      gap: 8px;
      .saveButton {
        border-radius: 8px;
        text-transform: none;
        background-color: var(--primary-dark);
        color: #fff;
        border: 1px solid var(--primary-dark);
        cursor: pointer;
        &:hover {
          box-shadow: 0px 1px 4px 0px var(--primary-dark);
        }
      }
      .closeButton {
        border-radius: 8px;
        text-transform: none;
        background-color: #fff;
        color: var(--primary-dark);
        border: 1px solid var(--primary-dark);
        cursor: pointer;
        &:hover {
          box-shadow: 0px 1px 4px 0px var(--primary-dark);
        }
      }
    }
  }
  .dialogContent {
    display: flex;
    flex-direction: row;
    gap: 8px;
    .graph {
      flex: 1;
      border-right: 1px solid #ebebeb;
    }
    .editor {
      padding-top: 16px;
      min-width: 25vw;
    }
  }
}
