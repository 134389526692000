.root {
  height: inherit;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 2px;
  .tableName {
    font-weight: 600;
    box-shadow: unset;
    width: 100%;
    font-size: 12px;
    color: #000;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: none;
    border-radius: 4px;
    &:hover {
      background-color: transparent;
    }
    svg {
      font-size: 20px;
      margin-top: 1px;
    }
    .content {
      width: 100%;
      .title {
        text-align: left;
      }
      .colDetails {
        width: 100%;
        display: flex;
        flex-direction: row;
        cursor: text;
        padding: 2px;
        .name {
          padding: 0px 8px;
          user-select: all;
          width: 100%;
          border-radius: 4px;
          text-align: left;
          &:hover {
            background-color: #ebebeb;
          }
          font-weight: 500;
        }
        .type {
          margin-left: auto;
          z-index: 100;
          font-weight: 400;
          border-radius: 4px;
          padding: 0px 8px;
          border: 1px solid #ebebeb;
        }
      }
    }
  }
}
