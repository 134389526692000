.queryDrawer {
  .paper {
    margin-top: 50px;
    margin-left: 40px;
    border-right: 1px solid #ebebeb;
    width: 180px;
  }

  .parent {
    padding: 12px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .text {
        font-size: 12px;
        font-weight: 700;
      }
      .iconButton {
        margin-left: auto;
        padding: 0px;
        svg {
          font-size: 18px;
        }
      }
    }
    .options {
      display: flex;
      button {
        padding: 0px;
        margin-left: auto;
        svg {
          font-size: 18px;
        }
      }
    }
    .queries {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .query {
        .queryButton {
          justify-content: flex-start;
          text-transform: none;
          align-items: center;
          width: 100%;
          padding: 4px 12px;
          font-size: 12px;
          color: #000;
          &:hover {
            background-color: #ebebeb;
            .deleteQuery {
              display: flex;
            }
          }
          .deleteQuery {
            padding: 0px;
            display: none;
            svg {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.popover {
  .container {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    .queryButton {
      &:hover {
        background-color: #ebebeb;
      }
      width: 100%;
      text-transform: none;
      font-weight: 600;
      color: #000;
      font-size: 12px;
    }
  }
}
