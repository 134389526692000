.root {
  margin-top: 64px;
  margin-left: 240px;
  margin-bottom: 64px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .title {
    display: flex;
    width: 50%;
    font-size: 24px;
    font-weight: 700;
    .right {
      margin-left: auto;
      display: flex;
      gap: 8px;
      .createButton {
        text-transform: none;
        padding: 4px 8px;
        background-color: #4b0082;
        font-weight: 600;
      }
    }
  }
  .customers {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 50%;
    .accordion {
      font-size: 14px;
      padding: 12px;
      font-weight: 600;
      .summary {
        svg {
          font-size: 18px;
          color: #000;
        }
      }
      .filterContainer {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        .name {
          display: flex;
          gap: 12px;
          align-items: center;
          padding: 12px 24px;
        }
      }
      .add {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 800;
        text-transform: none;
        color: #4b0082;
        &:hover {
          background-color: #faf8ff;
        }
      }
      .divider {
        margin: 8px 0px;
      }
      .footer {
        float: right;
        display: flex;
        gap: 4px;
        .save {
          font-size: 12px;
          font-weight: 800;
          text-transform: none;
          background-color: #4b0082;
        }
        .delete {
          font-size: 12px;
          font-weight: 800;
          text-transform: none;
        }
      }
    }
  }
}
