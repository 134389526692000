.dialogDetail {
  .paper {
    min-width: 55vw;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .subTitle {
    font-size: 14px;
    color: rgb(133, 133, 133);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .text {
      display: flex;
      gap: 12px;
    }

    .footer {
      margin-left: auto;

      .addButton {
        text-transform: none;
        margin: 4px;
        font-weight: 600;
        border-color: #4b0082;
        color: #4b0082;
        &:hover{
          background-color: #faf8ff;
        }
      }

      .button {
        text-transform: none;
        margin: 4px;
        background-color: #4b0082;
        font-weight: 800;
      }
      .disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}
