.queryEditor {
  .paper {
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    margin-left: var(--left-margin);
    font-size: 12px;
  }
  .parent {
    height: calc(100% - 36px);
    .dragger {
      border-radius: 4px;
      position: fixed;
      width: 100%;
      padding: 4px;
      cursor: ns-resize;
      background-color: #ebebeb;
    }
    display: flex;
    flex-direction: column;
    .top {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      .title {
        padding: 0px 16px;
        font-weight: 600;
      }
      .minusIcon {
        margin-left: auto;
        svg {
          font-size: 14px;
        }
      }
    }
    .container {
      height: 100%;
      // padding: 0px 8px;
      display: flex;
      flex-direction: row;
      .left {
        flex: 50%;
        display: flex;
        // padding-right: 8px;
        flex-direction: column;
        height: 100%;
        border-right: 1px solid #ebebeb;
        .bar {
          padding: 4px 16px;
          align-items: center;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #ebebeb;
          .title {
            font-weight: 600;
          }
          .preview {
            margin-left: auto;
            padding: 2px 10px;
            text-transform: none;
            font-size: 12px;
            background-color: transparent;
            color: #4b0082;
            border: 1px solid #4b0082;
            font-weight: 700;
          }
          .run {
            margin-left: 8px;
            padding: 2px 10px;
            text-transform: none;
            font-size: 12px;
            background-color: #4b0082;
            color: #fff;
            font-weight: 700;
          }
        }
        .content {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          .selectSource {
            display: flex;
            gap: 12px;
            align-items: center;
            width: 100%;
            .title {
              font-weight: 600;
              flex: 1;
            }
          }
          .mongoOptions {
            display: flex;
            gap: 12px;
            align-items: center;
            flex-direction: column;
          }
        }
      }
      .right {
        flex: 50%;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .bar {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 3px 0px;
          border-bottom: 1px solid #ebebeb;
          .textButton {
            text-transform: none;
            font-size: 12px;
            font-weight: 600;
            padding: 4px 0px;
            color: #777;
            &:hover {
              background-color: #ebebeb;
            }
            &.active {
              color: #000;
            }
          }
        }
        .error{
          display: flex;
          padding: 12px;
          background-color: #ff94944b;
          color: #f15252;
          font-size: 12px;
          font-weight: 600;

          p{
            margin-top: 1.5em;
            margin-left: 0.5em;
          }
        }
        .linearPrimary {
          background-color: #faf8ff !important;
        }
        .linearBarPrimary {
          background-color: #4b0082 !important;
        }
      }
    }
  }
  pre {
    background-color: #FAF8FF;
    code {
      font-size: 12px;
      color: green;
      font-family: inherit;
    }
  }
}
