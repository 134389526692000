.root {
  padding: 0px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .select {
    display: flex;
    align-items: center;
    .chips {
      font-size: 10px;
      margin: 2px;
    }
  }
  .condition {
    padding: 8px 4px 0px 4px;
    display: flex;
    gap: 4px;
    .child {
      flex: 1
    }
    .chips {
      font-size: 10px;
      margin: 2px;
    }
  }
  .divider {
    font-size: 12px;
    font-weight: 600;
    svg {
      &:hover {
        color: red;
      }
    }
  }
}
