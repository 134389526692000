.root {
  height: 100vh;
  width: 100%;
  background: #faf8ff;
}

.canvaParent {
  display: flex;
  height: 100vh;
}

.welcomeNode {
  width: 30vw;
  border-radius: 8px;
  padding: 8px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .message {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .title {
        font-weight: 600;
        font-size: 12px;
      }
      .subtitle {
        font-size: 8px;
        color: #666;
      }
    }
    .iconButton {
      svg {
        color: #4b0082;
        font-size: 0.8rem;
      }
    }
  }
  .iconButton {
    svg {
      font-size: 10px;
      color: #4b0082;
    }
  }
  .suggestions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .top {
      gap: 4px;
      display: flex;
      flex-direction: row;
      .block {
        display: flex;
        border: 1px solid #ebebeb;
        background-color: #fff;
        cursor: pointer;
        &:hover {
          border: 1px solid #4b0082;
          box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
        }
        align-items: center;
        border-radius: 8px;
        padding: 8px;
        gap: 8px;
        flex: 1;
        .title {
          font-size: 8px;
          font-weight: 400;
        }
        svg {
          font-size: 10px;
          color: #4b0082;
        }
      }
    }
  }
}

.chatNode {
  width: 30vw;
  border: 1px solid #4b0082;
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
  .container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .message {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .iconButton {
    svg {
      color: #4b0082;
      font-size: 0.8rem;
    }
  }
  .markdown {
    font-size: 8px;
  }
  .clarifyingQuestions {
    font-size: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .clarify {
      .question {
        font-weight: 600;
      }
      .choices {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        .choice {
          border-radius: 4px;
          padding: 4px;
          font-weight: 400;
          border: 1px solid #ebebeb;
          background-color: #fff;
          cursor: pointer;
          &:hover {
            border: 1px solid #4b0082;
            box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
          }
        }
        .selected {
          border: 1px solid #4b0082;
          box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
        }
      }
    }
  }
}

.dataNode {
  width: 30vw;
  border: 1px solid #4b0082;
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
  .container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .suggestions {
      overflow-x: auto;
      display: flex;
      flex-direction: row;
      gap: 8px;
      .block {
        display: flex;
        border: 1px solid #ebebeb;
        background-color: #fff;
        cursor: pointer;
        &:hover {
          border: 1px solid #4b0082;
          box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
        }
        align-items: center;
        border-radius: 8px;
        padding: 8px;
        gap: 8px;
        flex: 1;
        .title {
          font-size: 8px;
          font-weight: 400;
          width: max-content;
        }
        svg {
          font-size: 10px;
          color: #4b0082;
        }
      }
    }
    .suggestions::-webkit-scrollbar {
      display: none;
    }
    .loading {
      width: fit-content;
      display: flex;
      align-items: center;
      font-size: 8px;
      font-weight: 600;
      padding: 0px 4px;
      background-color: #faf8ff;
      border: 1px solid #ebebeb;
      border-radius: 4px;
    }
    .graph {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: 12px;
      min-height: 20vh;
      padding: 0px 12px 0px 4px;
      .graphContainer {
        min-height: 15vh;
      }
    }
    .message {
      font-size: 12px;
      font-weight: 600;
    }
    .sqlEditor {
      margin-bottom: 8px;
    }
    .sqlBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 12px;
      label {
        margin-left: 0px;
        span {
          font-weight: 600;
          font-size: 8px;
        }
      }
      .iconButton {
        height: 1em;
        width: 1em;
        svg {
          color: #4b0082;
        }
      }
      .feedback {
        display: flex;
        gap: 2px;
        position: relative;
      }
    }
    .tile {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: 12px;
      padding: 0px 12px 12px 12px;
    }
    .textButton {
      text-transform: none;
      color: #4b0082;
      font-size: 8px;
      border-radius: 8px;
      border: 1px solid rgba(224, 224, 224, 1);
      background-color: #faf8ff;
      svg {
        font-size: 10px;
      }
      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
      }
    }
    .iconButton {
      svg {
        color: #4b0082;
        font-size: 0.8rem;
      }
    }
  }
  .markdown {
    font-size: 8px;
  }
}

.loadingNode {
  width: 30vw;
  border: 1px solid #4b0082;
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .message {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      margin: auto;
    }
    .linearPrimary {
      background-color: #faf8ff !important;
    }
    .linearBarPrimary {
      background-color: #4b0082 !important;
    }
    .animBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      height: 8vh;
      .icon {
        text-transform: none;
        z-index: 100;
        color: #4b0082;
      }
      .loadingMessage {
        color: #666;
        text-align: center;
        font-size: 8px;
        font-weight: 200;
      }
    }
  }
}

:global(.react-flow .react-flow__handle) {
  // display: none;
  width: 4px;
  height: 4px;
  background-color: #4b0082;
}

.pane {
  .top {
    display: flex;
    .button {
      margin: 12px;
      border-radius: 8px;
      text-transform: none;
      background-color: #fff;
      z-index: 100;
      color: #4b0082;
      border: 1px solid #4b0082;
      cursor: pointer;
      &:hover {
        background-color: #faf8ff;
      }
    }
    .closeButton {
      margin-top: 12px;
      margin-right: 12px;
      margin-left: auto;
      height: 2.2rem;
      background-color: #fff;
      color: #4b0082;
      border-radius: 8px;
      z-index: 100;
      border: 1px solid #4b0082;
      cursor: pointer;
      &:hover {
        background-color: #faf8ff;
      }
      svg {
        font-size: 18px;
      }
    }
  }
}

.addDashboardPopover {
  .paper {
    width: 10rem;
    margin-top: 8px;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    button {
      text-transform: none;
      color: #4b0082;
      &:hover {
        background: #faf8ff;
      }
    }
  }
}

.editChartDialog {
  .titleContainer {
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .right {
      margin-left: auto;
      display: flex;
      gap: 8px;
      .saveButton {
        border-radius: 8px;
        text-transform: none;
        background-color: #4b0082;
        color: #fff;
        border: 1px solid #4b0082;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
        }
      }
      .closeButton {
        border-radius: 8px;
        text-transform: none;
        background-color: #fff;
        color: #4b0082;
        border: 1px solid #4b0082;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
        }
      }
    }
  }
  .dialogContent {
    display: flex;
    flex-direction: row;
    gap: 8px;
    .graph {
      flex: 1;
      border-right: 1px solid #ebebeb;
    }
    .editor {
      padding-top: 16px;
      min-width: 25vw;
    }
  }
}

.popoverZoomedOut {
  width: 100px;
  .paper {
    margin-top: 8px;
    padding: 0em;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
    width: 30%;
  }
}
.feedbackPopper {
  width: 450px;
  .paper {
    margin-top: 8px;
    padding: 0.5em;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
  }
  .footer {
    display: flex;
    button {
      margin-left: auto;
      text-transform: none;
      background: #4b0082;
      font-weight: 800;
    }
  }
}
.placeholder div {
  background: #eee;
  width: 100%;
  height: 8px;
  margin-bottom: 2px;
}
.placeholder div:nth-last-child(1) {
  width: 20%;
  margin-left: auto;
  background: rgba(115, 40, 171, 0.6);
}
