.root {
  .paper {
    min-width: 500px;
    min-height: 500px;
    border-radius: 16px;
    background: linear-gradient(to bottom, #530082 50%, #faf8ff 50%);
    box-shadow: 0px -15px 119px 0px rgba(36, 5, 60, 0.5) inset;
    padding: 24px;
  }
  .title {
    color: #fff;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
  .subTitle {
    margin-top: 16px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.06px;
  }
  .media {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    border-radius: 100px;
    img {
      border-radius: 12px;
      box-shadow: 0px 4px 12px 2px rgba(75, 0, 130, 0.4);
    }
  }
  .footer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    .cta {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      padding: 8px 12px 8px 12px;
      font-weight: 00;
      line-height: 170%;
      text-transform: none;
      background-color: #530082;
    }
  }
}
