.root {
  .paper {
    min-width: 500px;
    padding: 12px 32px 32px 32px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }
    .text {
      font-size: 14px;
      line-height: 170%;
    }
    .list {
      padding: 0px 24px 0px 24px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      gap: 24px;
    }
    .button {
      text-transform: none;
      color: #666;
      font-weight: 800;
      border-radius: 8px;
      border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
      background: #fff;
      &:hover {
        box-shadow: 0px 0px 12px 0px rgba(104, 34, 155, 0.3);
        color: #333;
        span {
          img{
            fill: #333;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
}
