.root {
  flex-direction: column;
  display: flex;
  margin-left: 240px;
  margin-top: 64px;
  width: calc(100% - 240px);
  height: calc(100vh - 64px);
  background: #faf8ff;

  .skelton {
    margin: 24px;
    padding: 24px;
    gap: 36px;
    display: flex;
    flex-direction: column;
    .inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      .skeltonBar {
        border-radius: 8px;
      }
    }
  }

  .iconButton {
    svg {
      font-size: 20px;
      color: #4b0082;
    }
  }

  .instruction {
    display: flex;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
    }
    align-items: center;
    gap: 8px;
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
    // background: #fff;
    color: #585858;
    margin: 14px 0px 14px 128px;
    font-size: 14px;
    line-height: 170%; /* 27.2px */
  }
}
