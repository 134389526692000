.root{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;

  .label{
    font-size: 14px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
  }
}