.dialog {
  .paper {
    min-width: 500px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  // .subTitle {
  //     font-size: 12px;
  //     color: rgb(133, 133, 133);
  // }

  .content {
    background: #faf8ff;
    border: 1px dashed #7e86a0;
    border-radius: 8px;
    margin: 16px;
    padding: 64px 0;
    .dropzone {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .text {
        color: #1f2129;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-top: 40px;
      }
      .divider {
        color: #7e86a0;
        font-size: 10px;
        font-weight: 400;
        margin: 0px 32px 0px 32px;
      }
      button {
        text-transform: none;
        margin: 0px 150px 0px 150px;
        background: #4B0082;
        font-weight: 800;
      }
    }
  }
}
