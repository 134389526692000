.dialog {
  .paper {
    min-width: 50vw;
    padding: 12px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }
  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .content {
    display: flex;
    gap: 8px;
    .tile {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 16px;
        border-radius: 8px;
        background: #f8f8f8;
        border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
        gap: 8px;
        .heading {
            font-weight: 700;
        }
        .info {
            font-size: 14px;
        }
        .button {
            text-transform: none;
            padding: 2px;
            background: #4b0082;
            margin-top: 4px;
        }
    }
  }
}
