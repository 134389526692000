:global(.react-grid-item.react-grid-placeholder) {
  background-color: rgba(115, 40, 171, 0.6) !important;
}
:global(.react-resizable-handle::after) {
  border-right: 2px solid #4b0082 !important;
  border-bottom: 2px solid #4b0082 !important;
}
.root {
  margin-left: 240px;
  height: calc(100vh - 64px);
  margin-top: 64px;
  width: calc(100% - 240px);
  background: #faf8ff;
  .header {
    padding: 12px 12px 0px 36px;
    display: flex;
    align-items: center;
    .content {
      .title {
        font-size: 24px;
        font-weight: 700;
      }
      .description {
        font-size: 14px;
        color: rgb(133, 133, 133);
      }
    }
    button {
      margin-left: 8px;
      text-transform: none;
      padding: 8px 16px;
      height: 40px;
      border-radius: 8px;
      background: #590895;
      color: #fff;
      font-weight: 800;
      &:hover {
        box-shadow: 0px 2px 12px 0px rgba(104, 34, 155, 0.4);
      }
    }
  }
  .top {
    display: flex;
    .iconButtonSmall {
      margin-left: auto;
      svg {
        font-size: 14px;
        color: #666;
      }
    }
  }
  .iconButton {
    svg {
      font-size: 20px;
      color: #4b0082;
    }
  }
  .gridContainer {
    background: #faf8ff;
    .newTile {
      border-radius: 20px;
      border-bottom-right-radius: 0px;
      border: 1px solid var(--stroke-color, #eff0f6);
      background: #fff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 14px;
        color: #666;
        font-weight: 500;
      }
      .subTitle {
        margin-top: 4px;
        color: #000;
        font-size: 24px;
        font-weight: 700;
      }
    }
    .tile {
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
      background: var(--white, #fff);
      &:hover {
        border: 1px solid var(--brand-color, #4b0082);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
      }
      .content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}

.dialogDetail {
  .paper {
    min-width: 700px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .subTitle {
    font-size: 14px;
    color: rgb(133, 133, 133);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 6px;
      flex: 1;

      .label {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .footer {
      margin-left: auto;

      .button {
        text-transform: none;
        margin: 4px;
        background: #4b0082;
        font-weight: 800;
      }
    }
  }
}

.morePopover {
  .paper {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    button {
      text-transform: none;
      color: #656565;
      font-size: 12px;
      &:hover {
        background: transparent;
        color: #4b0082;
      }
    }
  }
}

.dialog {
  .paper {
    min-width: 60vw;
    border-radius: 8px;
    padding-bottom: 24px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .content {
    .header {
      display: flex;
      padding-bottom: 8px;
      .textButton {
        margin-left: auto;
        text-transform: none;
        color: #4b0082;
        border-radius: 8px;
        border: 1px solid rgba(224, 224, 224, 1);
        background-color: #faf8ff;
        svg {
          font-size: 14px;
        }
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(104, 34, 155, 0.4);
        }
      }
    }
  }
}

:global(.monaco-editor) {
  padding-top: 12px;
}

.graphPopper {
  .paper {
    margin-top: 8px;
    overflow: hidden;
    padding: 4px;
    box-shadow: 0px 2px 15px 0px rgba(115, 40, 171, 0.6);
  }
  .iconButton {
    svg {
      font-size: 14px;
      color: #4b0082;
    }
  }
  .footerNudge {
    margin-top: 4px;
    display: flex;
    gap: 4px;
    font-size: 14px;
  }
}

.chatDialog {
  .paper {
    min-width: 80vw;
    height: 85vh;
    border-radius: 8px;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
  .toolbar {
    display: flex;
    .activeConnection {
      border-radius: 8px;
      color: #fff;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      font-weight: 600;
      border-radius: 8px;
      background: #270044;
      font-size: 14px;
      gap: 12px;
      &:hover {
        border: 1px solid var(--stroke, rgba(239, 217, 255, 0.6));
      }
    }
  }
}
